
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import JnSearchResultElement from '@/views/search/SearchResultElement.vue';
import EventBus from '@/services/event.bus';
import { Pagination } from '@/models/pagination.model';
import JnPaywall from '@/views/auth/Paywall.vue';
import { EsService } from '@/services/es.service';
import { JnService } from '@/services/jn.service';

@Component({
  components: { JnSearchResultElement, JnPaywall },
})
export default class JnSearchResults extends Vue {
  /*@Prop({ required: true })
  searchData!: SearchData;*/

  @Prop({ required: false, default: true })
  showInfo!: number;

  @Prop({ required: false })
  scope!: string;

  pagination?: Pagination;

  allDocs: Document[] = [];
  shownDocs: Document[] = [];

  totalResults = 0;
  paywallMessage = 'Sono elencati i primi 10 documenti.';

  created() {
    this.pagination = new Pagination({
      page: 1,
      total: 0,
      perPage: 10,
      visible: 6,
    });
    EventBus.$on('search-data', async (searchData: SearchData) => {
      console.log(
            'SearchResults.search-data: ' +
              JSON.stringify(searchData, null, 4)
          );
      console.log(
        'SearchResults.search-data(scope): ' + this.scope
      );
      if (
        searchData &&
        searchData.searchScope &&
        this.scope == searchData.searchScope
      ) {
        this.$store.commit('searchData', searchData);

        if (searchData.perPage) {
          this.pagination = new Pagination({
            page: 1,
            total: 0,
            perPage: searchData.perPage,
            visible: 6,
          });
          this.totalResults = await EsService.countDocs(searchData);
          console.log(
            'SearchResults.search-data(totalResults): ' +
            this.totalResults
          );
          await JnService.addSearchStat(
            searchData.keywords!.join(" "),
            searchData.searchScope!,
            this.totalResults
          );
        } else {
          this.totalResults = 0;
        }
      }
    });
    EventBus.$on('search-results', (docs: Document[]) => {
      this.shownDocs = [];
      this.allDocs = docs;
      console.log(
        'SearchResults.search-results(count):' +
          JSON.stringify(this.allDocs.length)
      );
      if (this.allDocs && this.allDocs.length > 0) {
        this.show(0, this.paywallOk ? this.pagination!.perPage : 10);
        this.pagination!.total = this.paywallOk ? this.allDocs.length : 10;
      }
    });
  }

  async mounted() {
    if (this.$store.getters.searchData) {
      this.allDocs = this.$store.getters.searchResults!;
      this.totalResults = await EsService.countDocs(
        this.$store.getters.searchData!
      );
      this.show(0, this.pagination!.perPage);
      if (this.allDocs) {
        this.pagination!.total = this.allDocs.length;
      }
      console.log(
        'SearchResults.mounted(searchDataStored): ' +
          JSON.stringify(this.$store.getters.searchData, null, 4)
      );
    }
  }

  show(from: number, size: number) {
    this.shownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.allDocs[i]) {
        this.shownDocs.push(this.allDocs[i]);
      }
    }
  }

  next() {
    if (this.pagination) {
      const from = (this.pagination.page - 1) * this.pagination.perPage;
      const size = this.pagination.perPage;
      this.show(from, size);
    }
  }

  get paywallOk(): boolean {
    const crmInfo = this.$store.getters.crmInfo;
    if (crmInfo) {
      if (crmInfo.purchase) {
        return true;
      } else {
        this.paywallMessage =
          'Abbonamento scaduto. Sono elencati i primi 10 documenti.';
        return false;
      }
    }
    return false;
  }
}
