













import './assets/styles/style.css';
import './assets/styles/original_style.css';
import './assets/styles/juranews.css';

import JnAppBar from './views/home/AppBar.vue';
import JnAppFooter from './views/home/AppFooter.vue';
import { Component, Vue } from 'vue-property-decorator';
import {  onMessage } from 'firebase/messaging';
import getMessaging from '@/firebase';

@Component({
  components: {
    JnAppBar,
    JnAppFooter,
  },
  metaInfo: {
    title: 'JuraNews',
    htmlAttrs: {
      lang: 'it-IT',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', vmid: 'description', content: 'JuraNews'  },
    ],
  },
})
export default class App extends Vue {
  async beforeMount() {
    const messaging = getMessaging();
    try {
      onMessage(messaging, (payload) => {
        console.log('App.beforeMount(message)', payload);
      });
    } catch (err) {
      console.error('App.beforeMount(general-err)', err);
    }
  }
}
