import axios from 'axios';
import { User } from '@/models/crm/user.model';
import { Purchase } from '@/models/crm/purchase.model';
import { Service } from '@/models/crm/service.model';
import { Picklist } from '@/models/picklist.model';
import { Dossier } from '@/models/dossier.model';
import { DossierDocument } from '@/models/dossier.document.model';
import {
  Categories,
  Sections,
  OrderingTypes,
  SearchTypes,
} from '@/models/enums.model';
import { Testata } from '@/models/testata.model';
import { Mail } from '@/models/mail.model';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Users } from "@/models/dashboard/users.model";
import { Conversation } from '@/models/ai/conversation.model';

class JuraNewsService {

  async getCrmUser(username: string | undefined, id: string | undefined) {
    let url = '';
    if (username) {
      url =
        process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
        '/contact?username=' +
        username;
    } else {
      url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/contact?id=' + id;
    }
    try {
      const vTigerAccess = String(
        await this.getConfig('VTIGER_ACCESS')
      ).localeCompare('true');
      let user: User | undefined;
      if (vTigerAccess != 0) {
        user = new User(
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          false
        );
      } else {
        const crmUser = (
          await axios.get(url, {
            headers: {
              apikey: process.env.VUE_APP_API_KEY,
            },
          })
        ).data;
        user = new User(
          crmUser.id,
          crmUser.cf_contacts_username,
          crmUser.email,
          crmUser.firstname,
          crmUser.lastname,
          crmUser.cf_contacts_profession,
          crmUser.cf_contacts_firstContact,
          crmUser.mobileNumber,
          crmUser.mailingcity,
          crmUser.mailingcountry,
          crmUser.mailingaddress,
          crmUser.mailingzip,
          crmUser.mailingstate,
          '',
          crmUser.cf_contacts_codicefiscale,
          '',
          '',
          '',
          crmUser.cf_contacts_administrator == 0 ? false : true
        );
      }
      return user;
    } catch (err) {
      return err.response.data;
    }
  }

  async getProfiles(username: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user/profile?username=' + username;
    try {
      const res = (
        await axios.get(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          }
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async login(username: string, password: string, maxNumSessions: number, productId: number) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/login';
    try {
      const res = (
        await axios.post(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: { username: username, password: password, sessionTimeout: process.env.VUE_APP_SESSION_TIMEOUT, maxNumSessions: maxNumSessions, productId: productId },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async logout(username: string, idSession: string, productId: number) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/logout';
    try {
      const res = (
        await axios.post(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: { username: username, idSession: idSession, productId: productId },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async resetPassword(username: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/password/reset';
    try {
      const res = (
        await axios.post(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: {
            username: username,
          },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async changePassword(
    username: string,
    currentPassword: string,
    newPassword: string
  ) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/password';
    try {
      const res = (
        await axios.put(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: {
            username: username,
            currentPassword: currentPassword,
            newPassword: newPassword,
          },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async getPurchaseOrder(id: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/purchase-order?id=' + id;
    try {
      const vTigerAccess = String(
        await this.getConfig('VTIGER_ACCESS')
      ).localeCompare('true');
      let service: Service | undefined;
      let purchaseOrder: Purchase | undefined;
      if (vTigerAccess != 0) {
        purchaseOrder = new Purchase(
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          undefined,
          undefined,
          '9999-12-31',
          undefined,
          Number(process.env.VUE_APP_MAX_NUM_SESSIONS)
        );
      } else {
        const poRes = (
          await axios.get(url, {
            headers: {
              apikey: process.env.VUE_APP_API_KEY,
            },
          })
        ).data;
        purchaseOrder = new Purchase(
          poRes.subject,
          poRes.LineItems[0].productid,
          poRes.contact_id,
          poRes.bill_country,
          poRes.bill_street,
          poRes.bill_code,
          poRes.bill_state,
          poRes.bill_city,
          undefined,
          undefined,
          poRes.cf_purchaseorder_enddate,
          undefined,
          Number(process.env.VUE_APP_MAX_NUM_SESSIONS)
        );
      }
      return purchaseOrder;
    } catch (err) {
      return err.response.data;
    }
  }

  async getService(serviceId: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/service?serviceId=' +
      serviceId;
    try {
      const vTigerAccess = String(
        await this.getConfig('VTIGER_ACCESS')
      ).localeCompare('true');
      let service: Service | undefined;
      if (vTigerAccess != 0) {
        service = new Service('', '', '', 0, '', '', Number(process.env.VUE_APP_PRODUCT_ID));
      } else {
        const serviceRes = (
          await axios.get(url, {
            headers: {
              apikey: process.env.VUE_APP_API_KEY,
            },
          })
        ).data;
        service = new Service(
          serviceRes.id,
          serviceRes.servicename,
          serviceRes.description,
          serviceRes.unit_price,
          serviceRes.cf_services_stripeid,
          serviceRes.cf_services_stripepriceid,
          serviceRes.cf_services_productid
        );
      }
      return service;
    } catch (err) {
      return err.response.data;
    }
  }

  async getLastPurchaseOrder(contactId: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/active-purchase-order?contactId=' +
      contactId;
    try {
      const vTigerAccess = String(
        await this.getConfig('VTIGER_ACCESS')
      ).localeCompare('true');
      let service: Service | undefined;
      let purchaseOrder: Purchase | undefined;
      if (vTigerAccess != 0) {
        purchaseOrder = new Purchase(
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          undefined,
          undefined,
          '9999-12-31',
          undefined,
          Number(process.env.VUE_APP_MAX_NUM_SESSIONS)
        );
      } else {
        const purchaseOrders = (
          await axios.get(url, {
            headers: {
              apikey: process.env.VUE_APP_API_KEY,
            },
          })
        ).data;
        for (const po of purchaseOrders) {
          if (po.postatus == 'Received Shipment') {
            let serviceId = undefined
            if (Array.isArray(po.lineItems)) {
              serviceId = po.lineItems[0].productid
            }
            else {
              serviceId = JSON.parse(JSON.stringify(Object.entries(po.lineItems)[0][1])).productid
            }
            // Verifica se il Purchase Order è per un servizio JuraNews
            if (serviceId) {
              const productId = (await this.getService(serviceId)).productId;
              if (productId == process.env.VUE_APP_PRODUCT_ID) {
                purchaseOrder = new Purchase(
                  po.subject,
                  serviceId,
                  po.contact_id,
                  po.bill_country,
                  po.bill_street,
                  po.bill_code,
                  po.bill_state,
                  po.bill_city,
                  undefined,
                  undefined,
                  po.cf_purchaseorder_enddate,
                  undefined,
                  Number(process.env.VUE_APP_MAX_NUM_SESSIONS)
                );
                return purchaseOrder;
              }
            }
          }
        }
      }
      return purchaseOrder;
    } catch (err) {
      return err.response.data;
    }
  }

  async getActivePurchaseOrder(contactId: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/active-purchase-order?contactId=' +
      contactId;
    try {
      const vTigerAccess = String(
        await this.getConfig('VTIGER_ACCESS')
      ).localeCompare('true');
      let purchaseOrder: Purchase | undefined;
      if (vTigerAccess != 0) {
        purchaseOrder = new Purchase(
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          undefined,
          undefined,
          '9999-12-31',
          undefined,
          Number(process.env.VUE_APP_MAX_NUM_SESSIONS)
        );
      } else {
        const purchaseOrders = (
          await axios.get(url, {
            headers: {
              apikey: process.env.VUE_APP_API_KEY,
            },
          })
        ).data;
        for (const po of purchaseOrders) {
          const now = moment();
          if (
            po.postatus == 'Received Shipment' &&
            now.isBefore(moment(po.cf_purchaseorder_enddate, 'YYYY-MM-DD'))
          ) {
            let serviceId = undefined
            if (Array.isArray(po.lineItems)) {
              serviceId = po.lineItems[0].productid
            }
            else {
              serviceId = JSON.parse(JSON.stringify(Object.entries(po.lineItems)[0][1])).productid
            }
            // Verifica se il Purchase Order è per un servizio JuraNews
            if (serviceId) {
              const productId = (await this.getService(serviceId)).productId;
              if (productId == process.env.VUE_APP_PRODUCT_ID) {
                purchaseOrder = new Purchase(
                  po.subject,
                  serviceId,
                  po.contact_id,
                  po.bill_country,
                  po.bill_street,
                  po.bill_code,
                  po.bill_state,
                  po.bill_city,
                  undefined,
                  undefined,
                  po.cf_purchaseorder_enddate,
                  undefined,
                  po.cf_purchaseorder_numeromassimosessioni ? po.cf_purchaseorder_numeromassimosessioni : Number(process.env.VUE_APP_MAX_NUM_SESSIONS)
                );
                return purchaseOrder;
              }
            }
          }
        }
      }
      return purchaseOrder;
    } catch (err) {
      return err.response.data;
    }
  }

  async changeStatusPurchaseOrder(id: string, status: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/purchase-order';
    try {
      const result = await axios({
        method: 'put',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: {
          id: id,
          status: status,
        },
      });
      return result.data;
    } catch (err) {
      return err.response;
    }
  }



  async sendMail(mail: Mail) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/sendmail';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: mail,
      });
      return result.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async sendRequestAbbonamento(
    mail: Mail,
    fileModulo: any,
    fileDistinta: any
  ) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/request-subscription';
    await axios({
      method: 'post',
      url: url,
      data: mail
    });
  }

  downloadModulo() {
    axios
      .get('modules/RichiestaTrial.pdf', { responseType: 'blob' })
      .then((response) => {
        saveAs(response.data, 'RichiestaTrial.pdf');
      });
  }

  async getPicklist(entityType: string, fieldName: string) {
    const picklist: Picklist[] = [];
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/picklist?entityType=' +
      entityType +
      '&fieldName=' +
      fieldName;
    const picklistRes = (
      await axios.get(url, {
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      })
    ).data;
    picklistRes.forEach(function (e: any) {
      picklist.push(new Picklist({ label: e.label, value: e.value }));
    });
    return picklist;
  }

  async getProvince() {
    const picklist: Picklist[] = [];
    picklist.push(new Picklist({ label: 'Agrigento', value: 'Agrigento' }));
    picklist.push(new Picklist({ label: 'Alessandria', value: 'Alessandria' }));
    picklist.push(new Picklist({ label: 'Ancona', value: 'Ancona' }));
    picklist.push(
      new Picklist({ label: 'Aosta', value: 'Aosta / Aoste (fr)' })
    );
    picklist.push(
      new Picklist({ label: 'Ascoli Piceno', value: 'Ascoli Piceno' })
    );
    picklist.push(new Picklist({ label: "L'Aquila", value: "L'Aquila" }));
    picklist.push(new Picklist({ label: 'Arezzo', value: 'Arezzo' }));
    picklist.push(new Picklist({ label: 'Asti', value: 'Asti' }));
    picklist.push(new Picklist({ label: 'Avellino', value: 'Avellino' }));
    picklist.push(new Picklist({ label: 'Bari', value: 'Bari' }));
    picklist.push(new Picklist({ label: 'Bergamo', value: 'Bergamo' }));
    picklist.push(new Picklist({ label: 'Biella', value: 'Biella' }));
    picklist.push(new Picklist({ label: 'Belluno', value: 'Belluno' }));
    picklist.push(new Picklist({ label: 'Benevento', value: 'Benevento' }));
    picklist.push(new Picklist({ label: 'Bologna', value: 'Bologna' }));
    picklist.push(new Picklist({ label: 'Brindisi', value: 'Brindisi' }));
    picklist.push(new Picklist({ label: 'Brescia', value: 'Brescia' }));
    picklist.push(
      new Picklist({
        label: 'Barletta-Andria-Trani',
        value: 'Barletta-Andria-Trani',
      })
    );
    picklist.push(
      new Picklist({ label: 'Bolzano', value: 'Bolzano / Bozen (de)' })
    );
    picklist.push(new Picklist({ label: 'Cagliari', value: 'Cagliari' }));
    picklist.push(new Picklist({ label: 'Campobasso', value: 'Campobasso' }));
    picklist.push(new Picklist({ label: 'Caserta', value: 'Caserta' }));
    picklist.push(new Picklist({ label: 'Chieti', value: 'Chieti' }));
    picklist.push(
      new Picklist({ label: 'Carbonia-Iglesias', value: 'Carbonia-Iglesias' })
    );
    picklist.push(
      new Picklist({ label: 'Caltanissetta', value: 'Caltanissetta' })
    );
    picklist.push(new Picklist({ label: 'Cuneo', value: 'Cuneo' }));
    picklist.push(new Picklist({ label: 'Como', value: 'Como' }));
    picklist.push(new Picklist({ label: 'Cremona', value: 'Cremona' }));
    picklist.push(new Picklist({ label: 'Crotone', value: 'Crotone' }));
    picklist.push(new Picklist({ label: 'Cosenza', value: 'Cosenza' }));
    picklist.push(new Picklist({ label: 'Catania', value: 'Catania' }));
    picklist.push(new Picklist({ label: 'Catanzaro', value: 'Catanzaro' }));
    picklist.push(new Picklist({ label: 'Enna', value: 'Enna' }));
    picklist.push(
      new Picklist({ label: 'Forlì-Cesena', value: 'Forlì-Cesena' })
    );
    picklist.push(new Picklist({ label: 'Ferrara', value: 'Ferrara' }));
    picklist.push(new Picklist({ label: 'Foggia', value: 'Foggia' }));
    picklist.push(new Picklist({ label: 'Firenze', value: 'Firenze' }));
    picklist.push(new Picklist({ label: 'Fermo', value: 'Fermo' }));
    picklist.push(new Picklist({ label: 'Frosinone', value: 'Frosinone' }));
    picklist.push(new Picklist({ label: 'Genova', value: 'Genova' }));
    picklist.push(new Picklist({ label: 'Gorizia', value: 'Gorizia' }));
    picklist.push(new Picklist({ label: 'Grosseto', value: 'Grosseto' }));
    picklist.push(new Picklist({ label: 'Imperia', value: 'Imperia' }));
    picklist.push(new Picklist({ label: 'Isernia', value: 'Isernia' }));
    picklist.push(new Picklist({ label: 'Latina', value: 'Latina' }));
    picklist.push(new Picklist({ label: 'Lecce', value: 'Lecce' }));
    picklist.push(new Picklist({ label: 'Lecco', value: 'Lecco' }));
    picklist.push(new Picklist({ label: 'Livorno', value: 'Livorno' }));
    picklist.push(new Picklist({ label: 'Lodi', value: 'Lodi' }));
    picklist.push(new Picklist({ label: 'Lucca', value: 'Lucca' }));
    picklist.push(new Picklist({ label: 'Macerata', value: 'Macerata' }));
    picklist.push(new Picklist({ label: 'Mantova', value: 'Mantova' }));
    picklist.push(
      new Picklist({ label: 'Massa-Carrara', value: 'Massa-Carrara' })
    );
    picklist.push(new Picklist({ label: 'Matera', value: 'Matera' }));
    picklist.push(
      new Picklist({ label: 'Medio Campidano', value: 'Medio Campidano' })
    );
    picklist.push(new Picklist({ label: 'Messina', value: 'Messina' }));
    picklist.push(new Picklist({ label: 'Milano', value: 'Milano' }));
    picklist.push(new Picklist({ label: 'Modena', value: 'Modena' }));
    picklist.push(
      new Picklist({ label: 'Monza e Brianza', value: 'Monza e Brianza' })
    );
    picklist.push(new Picklist({ label: 'Napoli', value: 'Napoli' }));
    picklist.push(new Picklist({ label: 'Novara', value: 'Novara' }));
    picklist.push(new Picklist({ label: 'Nuoro', value: 'Nuoro' }));
    picklist.push(new Picklist({ label: 'Ogliastra', value: 'Ogliastra' }));
    picklist.push(
      new Picklist({ label: 'Olbia-Tempio', value: 'Olbia-Tempio' })
    );
    picklist.push(new Picklist({ label: 'Oristano', value: 'Oristano' }));
    picklist.push(new Picklist({ label: 'Padova', value: 'Padova' }));
    picklist.push(new Picklist({ label: 'Palermo', value: 'Palermo' }));
    picklist.push(new Picklist({ label: 'Parma', value: 'Parma' }));
    picklist.push(new Picklist({ label: 'Pavia', value: 'Pavia' }));
    picklist.push(new Picklist({ label: 'Perugia', value: 'Perugia' }));
    picklist.push(
      new Picklist({ label: 'Pesaro e Urbino', value: 'Pesaro e Urbino' })
    );
    picklist.push(new Picklist({ label: 'Pescara', value: 'Pescara' }));
    picklist.push(new Picklist({ label: 'Piacenza', value: 'Piacenza' }));
    picklist.push(new Picklist({ label: 'Pisa', value: 'Pisa' }));
    picklist.push(new Picklist({ label: 'Pistoia', value: 'Pistoia' }));
    picklist.push(new Picklist({ label: 'Pordenone', value: 'Pordenone' }));
    picklist.push(new Picklist({ label: 'Potenza', value: 'Potenza' }));
    picklist.push(new Picklist({ label: 'Ravenna', value: 'Ravenna' }));
    picklist.push(
      new Picklist({ label: 'Reggio Calabria', value: 'Reggio Calabria' })
    );
    picklist.push(
      new Picklist({ label: 'Reggio Emilia', value: 'Reggio Emilia' })
    );
    picklist.push(new Picklist({ label: 'Ragusa', value: 'Ragusa' }));
    picklist.push(new Picklist({ label: 'Rieti', value: 'Rieti' }));
    picklist.push(new Picklist({ label: 'Rimini', value: 'Rimini' }));
    picklist.push(new Picklist({ label: 'Roma', value: 'Roma' }));
    picklist.push(new Picklist({ label: 'Rovigo', value: 'Rovigo' }));
    picklist.push(new Picklist({ label: 'Salerno', value: 'Salerno' }));
    picklist.push(new Picklist({ label: 'Sassari', value: 'Sassari' }));
    picklist.push(new Picklist({ label: 'Savona', value: 'Savona' }));
    picklist.push(new Picklist({ label: 'Siena', value: 'Siena' }));
    picklist.push(new Picklist({ label: 'Siracusa', value: 'Siracusa' }));
    picklist.push(new Picklist({ label: 'Sondrio', value: 'Sondrio' }));
    picklist.push(new Picklist({ label: 'La Spezia', value: 'La Spezia' }));
    picklist.push(new Picklist({ label: 'Taranto', value: 'Taranto' }));
    picklist.push(new Picklist({ label: 'Teramo', value: 'Teramo' }));
    picklist.push(new Picklist({ label: 'Terni', value: 'Terni' }));
    picklist.push(new Picklist({ label: 'Torino', value: 'Torino' }));
    picklist.push(new Picklist({ label: 'Trapani', value: 'Trapani' }));
    picklist.push(new Picklist({ label: 'Trento', value: 'Trento' }));
    picklist.push(new Picklist({ label: 'Treviso', value: 'Treviso' }));
    picklist.push(new Picklist({ label: 'Trieste', value: 'Trieste' }));
    picklist.push(new Picklist({ label: 'Udine', value: 'Udine' }));
    picklist.push(new Picklist({ label: 'Varese', value: 'Varese' }));
    picklist.push(
      new Picklist({
        label: 'Verbano-Cusio-Ossola',
        value: 'Verbano-Cusio-Ossola',
      })
    );
    picklist.push(new Picklist({ label: 'Venezia', value: 'Venezia' }));
    picklist.push(new Picklist({ label: 'Vercelli', value: 'Vercelli' }));
    picklist.push(new Picklist({ label: 'Verona', value: 'Verona' }));
    picklist.push(new Picklist({ label: 'Vicenza', value: 'Vicenza' }));
    picklist.push(new Picklist({ label: 'Viterbo', value: 'Viterbo' }));
    return picklist;
  }

  async getAllUsers() {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user';
    try {
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async getDocAccessStats(from: number, size: number, sortBy: string, sortOrder: string, dataDa: string, dataA: string) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/stats/access?offset=' + from + "&limit=" + size + "&sortBy=" + sortBy + "&sortOrder=" + sortOrder + "&dataDa=" + dataDa + "&dataA=" + dataA;
    try {
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async countDocAccessStats() {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/stats/access/count';
    try {
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async getDocSearchStats(from: number, size: number, sortBy: string, sortOrder: string, dataDa: string, dataA: string) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/stats/search?offset=' + from + "&limit=" + size + "&sortBy=" + sortBy + "&sortOrder=" + sortOrder + "&dataDa=" + dataDa + "&dataA=" + dataA;
    try {
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async countDocSearchStats() {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/stats/search/count';
    try {
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async getUsers(from: number, size: number, sortBy: string, sortOrder: string, dataDa: string, dataA: string, username: string) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user?offset=' + from + "&limit=" + size + "&sortBy=" + sortBy + "&sortOrder=" + sortOrder + "&dataDa=" + dataDa + "&dataA=" + dataA + "&username=" + username;
    try {
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async countUsers() {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user/count';
    try {
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async updateUser(user: Users) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user';
    try {
      const result = await axios({
        method: 'put',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: user,
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async registerUser(user: User) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: user,
      });
      return result;
    } catch (err) {
      return err.response;
    }
  }

  async addAccessDocStat(username: string, id: string, docUrl: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/stats/access/doc';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: { username: username, docId: id, docUrl: docUrl },
      });
      return result.data;
    } catch (err) {
      console.error(err);
      return err.response.data;
    }
  }

  async addSearchStat(keywords: string, scope: string, resultCount: number) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/stats/search';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: {
          searchKeywords: keywords,
          searchScope: scope,
          searchResultCount: resultCount,
        },
      });
      return result.data;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async purchase(purchase: Purchase) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/purchase';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: purchase,
      });
      return result.data;
    } catch (err) {
      return err;
    }
  }

  async pay(purchase: Purchase, successUrl: string, cancelUrl: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/create-checkout-session';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: {
          purchase,
          successUrl,
          cancelUrl,
        },
      });
      window.location.href = result.data.url;
    } catch (err) {
      return err.response.data;
    }
  }

  async fcmSendNotification(
    title: string,
    body: string,
    actionUrl: string,
    topicCondition: string
  ) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/fcm-send-notification';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
      data: {
        title: title,
        body: body,
        url: actionUrl,
        topicCondition: topicCondition,
      },
    });
    return result;
  }

  async fcmRegisterTopic(token: string, topic: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/fcm-register-topic';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
      data: { token: token, topic: topic },
    });
    return result;
  }

  async fcmUnregisterTopic(token: string, topic: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/fcm-unregister-topic';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
      data: { token: token, topic: topic },
    });
    return result;
  }

  async fcmGetTokens(username: string, client: string) {
    try {
      const url =
        process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
        '/fcm-token?username=' +
        username +
        '&client=' +
        client;
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result.data;
    } catch (err) {
      return err.response;
    }
  }

  async fcmSaveToken(username: string, client: string, token: string) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/fcm-token';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
      data: { username: username, client: client, token: token },
    });
    return result.data;
  }

  async createInvoice(purchase: Purchase) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/invoice';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: purchase,
      });
      return result.data;
    } catch (err) {
      return err.response.data;
    }
  }

  getQueryTypes(): Picklist[] {
    const tipiDocumento: Picklist[] = [];
    tipiDocumento.push(new Picklist({ value: 'OR', label: 'OR' }));
    tipiDocumento.push(new Picklist({ value: 'AND', label: 'AND' }));
    tipiDocumento.push(new Picklist({ value: 'EXACT', label: 'Frase esatta' }));
    return tipiDocumento;
  }

  getTipiDocumento(): Picklist[] {
    const tipiDocumento: Picklist[] = [];
    tipiDocumento.push(
      new Picklist({ value: Categories.ULTIME_NEWS, label: 'News' })
    );
    tipiDocumento.push(
      new Picklist({
        value: Categories.SINTESI_GIURISPRUDENZIALI,
        label: 'Sintesi Giurisprudenziale',
      })
    );
    tipiDocumento.push(
      new Picklist({
        value: Categories.SCHEDA_AUTORALE,
        label: 'Scheda Autorale',
      })
    );
    tipiDocumento.push(
      new Picklist({ value: Categories.COMMENTI, label: 'Commento' })
    );
    tipiDocumento.push(
      new Picklist({ value: Categories.MASSIME, label: 'Massime' })
    );
    tipiDocumento.push(
      new Picklist({ value: Categories.FORMULARI, label: 'Formulari' })
    );
    return tipiDocumento;
  }

  getTipiOrdinamento(): Picklist[] {
    const tipiOrdinamento: Picklist[] = [];
    tipiOrdinamento.push(
      new Picklist({ value: OrderingTypes.SCORE, label: 'Rilevanza' })
    );
    tipiOrdinamento.push(
      new Picklist({
        value: OrderingTypes.DATA_CREAZIONE,
        label: 'Cronologico',
      })
    );
    return tipiOrdinamento;
  }
  getSezioniCassazione(): Picklist[] {
    const tipiSentenza: Picklist[] = [];
    tipiSentenza.push(
      new Picklist({ value: "civile", label: 'Civile' })
    );
    tipiSentenza.push(
      new Picklist({ value: "penale", label: 'Penale' })
    );
    return tipiSentenza;
  }
  getSottoSezioniCassazioneCivile(): Picklist[] {
    const sottoSezioni: Picklist[] = [];
    sottoSezioni.push(
      new Picklist({ value: "I", label: 'I' })
    );
    sottoSezioni.push(
      new Picklist({ value: "II", label: 'II' })
    );
    sottoSezioni.push(
      new Picklist({ value: "III", label: 'III' })
    );
    sottoSezioni.push(
      new Picklist({ value: "IV - Lavoro", label: 'IV - Lavoro' })
    );
    sottoSezioni.push(
      new Picklist({ value: "V - Tributaria", label: 'V - Tributaria' })
    );
    sottoSezioni.push(
      new Picklist({ value: "VI", label: 'VI' })
    );
    sottoSezioni.push(
      new Picklist({ value: "Sezioni Unite", label: 'Sezioni Unite' })
    );
    return sottoSezioni;
  }
  getSottoSezioniCassazionePenale(): Picklist[] {
    const sottoSezioni: Picklist[] = [];
    sottoSezioni.push(
      new Picklist({ value: "prima", label: 'prima' })
    );
    sottoSezioni.push(
      new Picklist({ value: "seconda", label: 'seconda' })
    );
    sottoSezioni.push(
      new Picklist({ value: "terza", label: 'terza' })
    );
    sottoSezioni.push(
      new Picklist({ value: "quarta", label: 'quarta' })
    );
    sottoSezioni.push(
      new Picklist({ value: "quinta", label: 'quinta' })
    );
    sottoSezioni.push(
      new Picklist({ value: "sesta", label: 'sesta' })
    );
    sottoSezioni.push(
      new Picklist({ value: "unite", label: 'unite' })
    );
    sottoSezioni.push(
      new Picklist({ value: "feriale", label: 'feriale' })
    );
    return sottoSezioni;
  }
  getTipiRicercaGlobale(): Picklist[] {
    const tipiRicercaGlobale: Picklist[] = [];
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.ALL, label: 'Tutti' })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.NEWS, label: 'News' })
    );
    tipiRicercaGlobale.push(
      new Picklist({
        value: SearchTypes.AREE_TEMATICHE,
        label: 'Aree Tematiche',
      })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.BANCA_DATI, label: 'Banca Dati' })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.MASSIMARIO, label: 'Massimario' })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.FORMULARI, label: 'Formulari' })
    );
    return tipiRicercaGlobale;
  }

  getNumeroRisultati(): Picklist[] {
    const numeroRisultati: Picklist[] = [];
    numeroRisultati.push(new Picklist({ value: '20', label: '20' }));
    numeroRisultati.push(new Picklist({ value: '50', label: '50' }));
    numeroRisultati.push(new Picklist({ value: '100', label: '100' }));
    return numeroRisultati;
  }

  getRegioni(): Picklist[] {
    const regioni: Picklist[] = [];
    regioni.push(new Picklist({ value: '5', label: 'Regione Abruzzo' }));
    regioni.push(new Picklist({ value: '6', label: 'Regione Basilicata' }));
    regioni.push(new Picklist({ value: '7', label: 'Regione Calabria' }));
    regioni.push(new Picklist({ value: '8', label: 'Regione Campania' }));
    regioni.push(new Picklist({ value: '9', label: 'Regione Emilia-Romagna' }));
    regioni.push(new Picklist({ value: '10', label: 'Regione Friuli-Venezia Giulia' }));
    regioni.push(new Picklist({ value: '11', label: 'Regione Lazio' }));
    regioni.push(new Picklist({ value: '12', label: 'Regione Liguria' }));
    regioni.push(new Picklist({ value: '13', label: 'Regione Lombardia' }));
    regioni.push(new Picklist({ value: '14', label: 'Regione Marche' }));
    regioni.push(new Picklist({ value: '15', label: 'Regione Molise' }));
    regioni.push(new Picklist({ value: '16', label: 'Regione Piemonte' }));
    regioni.push(new Picklist({ value: '17', label: 'Regione Puglia' }));
    regioni.push(new Picklist({ value: '18', label: 'Regione Sardegna' }));
    regioni.push(new Picklist({ value: '19', label: 'Regione Sicilia' }));
    regioni.push(new Picklist({ value: '20', label: 'Regione Toscana' }));
    regioni.push(new Picklist({ value: '21', label: 'Regione Trentino-Alto Adige' }));
    regioni.push(new Picklist({ value: '22', label: 'Provincia autonoma di Bolzano' }));
    regioni.push(new Picklist({ value: '23', label: 'Provincia autonoma di Trento' }));
    regioni.push(new Picklist({ value: '24', label: 'Regione Umbria' }));
    regioni.push(new Picklist({ value: '25', label: "Regione Valle d'Aosta" }));
    regioni.push(new Picklist({ value: '26', label: 'Regione Veneto' }));
    return regioni;
  }

  getAnni(): Picklist[] {
    const anni: Picklist[] = [];
    for (let i = Number(moment().format('YYYY')); i >= 1900; i--) {
      anni.push(new Picklist({ value: String(i), label: String(i) }));
    }
    return anni;
  }

  async getDossier(username: string): Promise<Dossier> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier/' + username;
    const dossier = new Dossier(username, []);
    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
    });
    if (result.status == 200) {
      dossier.initFromJson(JSON.stringify(result.data.structure));
    }
    return dossier;
  }

  async getUserConversation(username: string): Promise<Conversation[]> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user/conversation/' + username;
    const conversations: Conversation[] = [];
    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
    });
    for (const conv of result.data) {
      const conversation = new Conversation(
                conv.username,
                conv.conversation_id
              );
      conversation.docs = conv.docs;
      conversation.title = conv.title;
      conversation.rating = conv.rating;
      conversation.ratingComment = conv.rating_comment;
      conversation.conversationDate = conv.conversation_date;
      conversations.push(conversation);
    }
    return conversations;
  }

  async getDossierDocument(id: number): Promise<DossierDocument> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier/document/' + id;
    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
    });
    const dossier = new DossierDocument(
      result.data.id,
      result.data.name,
      result.data.text,
      [],
      false
    );
    return dossier;
  }

  async deleteDossierDocument(id: string): Promise<string> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier/document/' + id;
    const result = await axios({
      method: 'delete',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
    });
    return result.data.id;
  }

  async saveDossierDocument(dossierDoc: DossierDocument): Promise<any> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier/document';
    let result = undefined;
    try {
      result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: dossierDoc,
      });
    } catch (err) {
      return err;
    }
    return result.data;
  }

  async updateDossierDocument(dossierDoc: DossierDocument): Promise<any> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier/document';
    let result = undefined;
    try {
      result = await axios({
        method: 'put',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: dossierDoc,
      });
    } catch (err) {
      return err;
    }
    return result.data;
  }

  async saveDossier(dossier: Dossier): Promise<Dossier> {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier';
    let result = undefined;
    try {
      result = await this.getDossier(dossier.username!);
      console.info(
        'JnService.saveDossier(current-dossier): ' + JSON.stringify(result)
      );
      result = await axios({
        method: 'put',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: dossier,
      });
    } catch (err) {
      result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: dossier,
      });
    }
    return dossier;
  }

  saveFile(filePath: string, fileName: string) {
    const oReq = new XMLHttpRequest();
    const URLToPDF = filePath;
    oReq.open('GET', URLToPDF, true);
    oReq.responseType = 'blob';
    oReq.onload = function () {
      const file = new Blob([oReq.response], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
    };
    oReq.send();
  }

  async spellCheck(text: string): Promise<string> {
    const url =
      process.env.VUE_APP_BING_SPELL_CHECK_URL +
      '?mkt=it-IT&mode=spell&text=' +
      text;

    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_BING_API_KEY,
      },
    });
    const flaggedTokens = result.data.flaggedTokens;
    console.info(
      'JnService.spellCheck(result): ' + JSON.stringify(flaggedTokens, null, 2)
    );
    const textTokens = text.split(' ');
    let textCorrected = text;
    for (const token of flaggedTokens) {
      let tmpTextCorrected = '';
      console.info(
        'JnService.spellCheck(token): ' + JSON.stringify(token, null, 2)
      );
      for (const tt of textTokens) {
        if (tt == token.token) {
          tmpTextCorrected += token.suggestions[0].suggestion + ' ';
        } else {
          tmpTextCorrected += tt + ' ';
        }
        textCorrected = tmpTextCorrected.trim();
      }
    }
    return textCorrected.trim();
  }

  async getConfig(name: string): Promise<any> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/config?name=' + name;
    const result = (
      await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      })
    ).data;
    return result;
  }

  async getAutocompleteSearch(): Promise<any> {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/autocomplete';
    const result = (
      await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      })
    ).data;
    return result;
  }

  getTestata(id: string): Testata {
    if (id == Categories.DIRITTO_SOCIETARIO) {
      return new Testata({
        id: id,
        name: 'DIRITTO SOCIETARIO',
        title: '',
        description:
          "Selezione delle tematiche più aggiornate relative all'impresa e alle società",
        image: '/img/aree-tematiche/testata-diritto-societario.jpg',
      });
    } else if (id == Categories.DIRITTO_PENALE) {
      return new Testata({
        id: id,
        name: 'DIRITTO PENALE',
        title: '',
        description:
          'Tutti gli ultimi aggiornamenti relativi al diritto penale',
        image: '/img/aree-tematiche/testata-diritto-penale.jpg',
      });
    } else if (id == Categories.DIRITTO_FALLIMENTARE) {
      return new Testata({
        id: id,
        name: 'DIRITTO FALLIMENTARE',
        title: '',
        description:
          'Tutti gli ultimi aggiornamenti relativi al fallimento e alle procedure concorsuali',
        image: '/img/aree-tematiche/testata-diritto-fallimentare.jpg',
      });
    } else if (id == Categories.DIRITTO_TRIBUTARIO) {
      return new Testata({
        id: id,
        name: 'DIRITTO TRIBUTARIO - FISCALE - DOGANALE',
        title: '',
        description:
          'Aspetti tributari e fiscali e le problematiche doganali esaminati dai nostri consulenti',
        image: '/img/aree-tematiche/testata-diritto-tributario.png',
      });
    } else if (id == Categories.DIRITTO_DEL_LAVORO) {
      return new Testata({
        id: id,
        name: 'DIRITTO DEL LAVORO',
        title: '',
        description:
          'Panoramica completa degli aspetti legali e giurisprudenziali, compreso il rinvio alle circolari che semplificano l’applicazione delle norme',
        image: '/img/aree-tematiche/testata-diritto-lavoro.png',
      });
    } else if (id == Categories.DIRITTO_AMMINISTRATIVO) {
      return new Testata({
        id: id,
        name: 'DIRITTO AMMINISTRATIVO',
        title: '',
        description:
          "I più recenti aggiornamenti sulla giustizia amministrativa",
        image: '/img/aree-tematiche/testata-diritto-amministrativo.jpg',
      });
    } else if (id == Categories.ASSICURAZIONI) {
      return new Testata({
        id: id,
        name: 'ASSICURAZIONI - RISARCIMENTO DANNI',
        title: '',
        description:
          'Le notizie più recenti in materia di assicurazione e risarcimento del danno',
        image: '/img/aree-tematiche/testata-assicurazioni.png',
      });
    } else if (id == Categories.DIRITTO_FAMIGLIA) {
      return new Testata({
        id: id,
        name: 'DIRITTO FAMIGLIA - SUCCESSIONI',
        title: '',
        description:
          'Tutte le ultime novità sul diritto di famiglia e le successioni',
        image: '/img/aree-tematiche/testata-diritto-di-famiglia.png',
      });
    } else if (id == Categories.MERCATI_IMMOBILIARI) {
      return new Testata({
        id: id,
        name: 'MERCATI IMMOBILIARI',
        title: '',
        description:
          'Approfondimenti in materia di mercati immobiliari e strumenti contrattuali',
        image: '/img/aree-tematiche/testata-mercato-immobiliare.jpg',
      });
    } else if (id == Categories.PRIVACY) {
      return new Testata({
        id: id,
        name: 'PROTEZIONE DEI DATI PERSONALI',
        title: '',
        description:
          'La materia della privacy accuratamente trattata in tutti i suoi aspetti',
        image: '/img/aree-tematiche/testata-privacy.jpg',
      });
    }
    else if (id == Categories.DIRITTO_NUOVE_TECNOLOGIE) {
      return new Testata({
        id: id,
        name: 'DIRITTO DELLE NUOVE TECNOLOGIE',
        title: '',
        description:
          "L'evoluzione della giurisprudenza tra diritto ed etica",
        image: '/img/aree-tematiche/testata-diritto-nuove-tecnologie.jpg',
      });
    }
    else if (id == Categories.CONTRATTUALISTICA_DIRITTO_PRIVATO) {
      return new Testata({
        id: id,
        name: 'CONTRATTUALISTICA DI DIRITTO PRIVATO',
        title: '',
        description:
          'Aspetti legali e giurisprudenziali della contrattualistica di diritto privato',
        image: '/img/aree-tematiche/testata-contratti.jpg',
      });
    } else if (id == Categories.BANCARIO_MERCATI_FINANZIARI) {
      return new Testata({
        id: id,
        name: 'BANCARIO E MERCATI FINANZIARI',
        title: '',
        description:
          'Il diritto bancario e la disciplina dei mercati finanziari aggiornati e commentati',
        image: '/img/aree-tematiche/testata-mercati-finanziari.png',
      });
    } else if (id == Categories.CYBERSICUREZZA) {
      return new Testata({
        id: id,
        name: 'CYBERSICUREZZA',
        title: '',
        description: 'Testo',
        image: '/img/testata-news.png',
      });
    } else if (id == Categories.PROCESSO_TELEMATICO) {
      return new Testata({
        id: id,
        name: 'DIRITTO PROCESSUALE TELEMATICO',
        title: '',
        description:
          'Aspetti normativi e riforme del processo telematico e del diritto processuale, con l’analisi delle ultime pronunce giurisprudenziali',
        image: '/img/aree-tematiche/testata-processo-telematico.jpg',
      });
    } else if (id == Categories.ULTIME_NEWS) {
      return new Testata({
        id: id,
        name: 'NEWS',
        title: '',
        description:
          'Una rassegna quotidiana per una "prima lettura" ed interpretazione dei principali fatti giuridici',
        image: '/img/testata-news.png',
      });
    } else if (id == Sections.BANCA_DATI) {
      return new Testata({
        id: id,
        name: 'BANCA DATI',
        title: '',
        description:
          'Un esclusivo patrimonio documentale, completo di correlazioni e testi previgenti, per una visione sistematica del quadro normativo',
        image: '/img/testata-banca-dati.jpg',
      });
    } else if (id == Sections.CODICI) {
      return new Testata({
        id: id,
        name: 'CODICI',
        title: '',
        description:
          'Costituzione, Codici e Norme principali sempre a portata di mano',
        image: '/img/testata-codici.png',
      });
    } else if (id == Sections.COMMON_SEARCH_RESULT) {
      return new Testata({
        id: id,
        name: 'Ricerca generale',
        title: '',
        description:
          "Ricerca sull'intero, esclusivo patrimonio documentale di JuraNews",
        image: '/img/testata-banca-dati.jpg',
      });
    } else if (id == Sections.REAL_TIME) {
      return new Testata({
        id: id,
        name: 'REAL TIME',
        title: '',
        description:
          'Tutti i testi in tempo reale: un osservatorio privilegiato per essere costantemente aggiornati',
        image: '/img/testata-realtime.png',
      });
    } else if (id == Sections.MASSIMARIO) {
      return new Testata({
        id: id,
        name: 'MASSIMARIO',
        title: '',
        description:
          'Le più importanti sentenze di legittimità e di merito massimate dal nostro team di autori',
        image: '/img/testata-massimario.jpg',
      });
    }
    else if (id == Categories.FORMULE) {
      return new Testata({
        id: id,
        name: 'FORMULARI',
        title: '',
        description:
          'Modelli dei principali atti giudiziari',
        image: '/img/testata-formulario.jpg',
      });
    }
    else if (id == Sections.PUBBLICAZIONI) {
      return new Testata({
        id: id,
        name: 'JURANEWS Lex & Law',
        title: '',
        description:
          'Le pubblicazioni per una conoscenza aggiornata, sistematica e di orientamento',
        image: '/img/testata-pubblicazioni.jpg',
      });
    }
    else if (id == Sections.VIDEO) {
      return new Testata({
        id: id,
        name: 'Video e Podcast',
        title: '',
        description:
          'JURANEWS WebTv: Approfondimenti, Webinar, Reportage, Esclusive, Informazione',
        image: '/img/testata-video.jpg',
      });
    }
    return new Testata({
      id: '0',
      name: '',
      title: '',
      description: '',
      image: '',
    });
  }
}

export const JnService = new JuraNewsService();
